<template>
	<div class="mypage_content_wrap">
		<h2>좋아요 리스트</h2>
		<div class="mypage_content_box_wrap">
			<div class="mypage_content_box">
				<div class="mypage_tit_wrap">
					<h4>나의 좋아요 리스트</h4>
					<div @click="checked_valid = true" v-if="checked_valid === false" class="edit d_block">편집</div>
					<div v-if="checked_valid === true" class="completion">
						<ul>
							<li v-if="all_ck_valid === false" @click="check_all()">전체선택</li>
							<li v-if="all_ck_valid === true" @click="check_all_cancle()">선택해제</li>
							<li @click="checked_valid = false">편집취소</li>
							<li @click="check_delete()">삭제완료</li>
						</ul>
					</div>
				</div>
				<div v-for="(item, index) in like_list" :key="index" class="mypage_list_box">
					<input v-if="checked_valid === true" id="check_test" type="checkbox" />
					<div class="mypage_list_img">
						<img alt="" :src="`${$image_host}/contents/${item.thumb_path}`" />
					</div>
					<ul class="tit_wrap d_none_533">
						<li class="bold">제목</li>
						<li class="bold">장르</li>
						<li @click="$router.push(`/detail/${item.opus_no}`)" class="btn">바로보기</li>
					</ul>
					<ul class="info_wrap">
						<li>{{ item.opus_title }}</li>
						<li>{{ item.genre }}</li>
						<li @click="$router.push(`/detail/${item.opus_no}`)" class="btn">바로보기</li>
					</ul>
				</div>
			</div>
		</div>
		<div v-if="jwt.getToken('id_token')" class="pagenation_wrap">
			<ul class="pagenation">
				<Pagination
					v-if="like_list.length > 0"
					@changePage="page_active"
					:page="page"
					:totalPage="totalPage"
				></Pagination>
			</ul>
		</div>
	</div>
</template>

<script setup>
import ajax from '@/api/api-manager';
import Pagination from '@/shared-components/Pagination.vue';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import jwt from '@/api/LocalStorage';

const router = useRouter();
const current_page = ref(1);
const page = ref(null);
const totalPage = ref(null);
const like_list = ref([]);
const list_idx = ref([]);
const checked_valid = ref(false);
const all_ck_valid = ref(false);

onMounted(() => {
	if (jwt.getToken('id_token')) {
		getLikeContentList();
	}
});

// 삭제할 작품 체크
const check_delete = () => {
	const checked_data = document.querySelectorAll('#check_test');
	if (confirm('선택하신 작품들을 목록에서 삭제하시겠습니까?')) {
		Array.from(checked_data).map((p, idx) => {
			if (p.checked) {
				delete_like_list(idx);
			}
		});
	}
};

// 전체 작품을 선택함
const check_all = () => {
	const checked_data = document.querySelectorAll('#check_test');
	Array.from(checked_data).map((p, idx) => {
		return (p.checked = true), (all_ck_valid.value = true);
	});
};

// 선택된 전체 작품을 해제함
const check_all_cancle = () => {
	const checked_data = document.querySelectorAll('#check_test');
	Array.from(checked_data).map((p, idx) => {
		return (all_ck_valid.value = false), (p.checked = false);
	});
};

// 좋아요 리스트 가져오기
const getLikeContentList = () => {
	ajax(`mypage/like_list`, current_page.value).then((res) => {
		like_list.value = res.data.data;
		page.value = res.data.page;
		totalPage.value = res.data.totalpage;
		like_list.value.map((p) => {
			if (p.genre === 'AC') {
				p.genre = '액션';
			} else if (p.genre === 'TR') {
				p.genre = '스릴러';
			} else if (p.genre === 'ML') {
				p.genre = '멜로';
			} else if (p.genre === 'RC') {
				p.genre = '로맨틱코미디';
			} else if (p.genre === 'HR') {
				p.genre = '호러';
			}
		});
	});
};

const page_active = (event) => {
	current_page.value = event;
	ajax('mypage/like_list', current_page.value).then((res) => {
		getLikeContentList();
	});
};

const delete_like_list = (index) => {
	const form = new FormData();
	form.append('seq', like_list.value[index].fav_seq);
	ajax('mypage/delete_like_list', form).then((res) => {
		getLikeContentList();
		const checked_data = document.querySelectorAll('#check_test');
		Array.from(checked_data).map((p, idx) => {
			return (p.checked = false);
		});
	});
};
</script>
